<template>
  <TheHeader v-if="isContentLoaded"/>
  <main class="main">
    <router-view></router-view>
  </main>
  <TheFooter v-if="isContentLoaded"/>
</template>

<script scoped>
import TheHeader from "@/components/layouts/TheHeader.vue";
import TheFooter from "@/components/layouts/TheFooter.vue";
export default {
  name: 'App',
  components: {
    TheHeader,
    TheFooter,
  },
  data() {
    return {
      isContentLoaded: false,
    }
  },  
  created() {
    window.addEventListener('load', this.handleLoad);
  },
  unmounted() {
    window.removeEventListener('load', this.handleLoad);
  },
  methods: {
    handleLoad() {
      this.isContentLoaded = true;
    },
  },
}
</script>
