<template>
    <footer id="head7" class="footer container">
        <div class="footer__container radius-30">
            <div class="footer__column">
                <a href="/" class="footer__icon">
                    <img :src="require('@/assets/icons/k-tech-bm-logo.svg')" alt="k-tech-icon">
                </a>
            </div>
            <div class="footer__column">
                <p class="footer__descr">
                    <!-- Все права защищены. <br>
                    Полное и частичное копирование материалов <br> без согласования с организаторами <br> конференции запрещено. 
                    При согласованном <br> использовании материалов необходима <br> ссылка на ресурс. -->
                    {{ $t('footer.text6') }}
                </p>
            </div>
            <div class="footer__column">
                <div class="footer__name">{{ $t('footer.text1') }}</div>
                <ul class="footer__list">
                    <li class="footer__item">
                        <a href="https://www.facebook.com/kommesk" target="_blank" class="footer__link">
                            <img :src="require('@/assets/icons/facebook-bm-logo.svg')" alt="kommesk-logo" class="footer__item-logo">
                            <span class="footer__span">Facebook</span>
                        </a>
                    </li>
                    <li class="footer__item">
                        <a href="https://www.youtube.com/@Kommesk-life" target="_blank" class="footer__link">
                            <img :src="require('@/assets/icons/youtube-bm-logo.svg')" alt="kommesk-logo" class="footer__item-logo">
                            <span class="footer__span">YouTube</span>
                        </a>
                    </li>
                    <li class="footer__item">
                        <a href="https://t.me/KgfKZBot" target="_blank" class="footer__link">
                            <img :src="require('@/assets/icons/telegram-bm-logo.svg')" alt="kommesk-logo" class="footer__item-logo">
                            <span class="footer__span">Telegram</span>
                        </a>
                    </li>
                    <li class="footer__item">
                        <a href="https://www.instagram.com/kommesk_life/" target="_blank" class="footer__link">
                            <img :src="require('@/assets/icons/instagram-bm-logo.svg')" alt="kommesk-logo" class="footer__item-logo">
                            <span class="footer__span">Instagram</span>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="footer__column">
                <div class="footer__name">{{ $t('footer.text2') }}</div>
                <a  href="https://kgforum.kz/" target="_blank" class="footer__logo">
                    <img :src="require('@/assets/icons/growth-logo.svg')" alt="growth-logo" />
                </a>
                <a href="https://kommesk.kz/" target="_blank" class="footer__logo">
                    <img :src="require('@/assets/icons/kommesk-logo.svg')" alt="kommesk-logo" />
                </a>
            </div>
            <div class="footer__column">
                <div class="footer__name">{{ $t('footer.text3') }}</div>
                <div class="footer__subname footer__subname-first">{{ $t('footer.text4') }}</div>
                <a class="footer__subdescr footer__subdescr-first" :href="'mailto:' + 'k.bazarbekov@kommesk-omir.kz'">
                    <strong class="footer__subdescr-strong">Кайрат Базарбеков - </strong>k.bazarbekov@kommesk-omir.kz
                </a>
                <a class="footer__subdescr footer__subdescr-first" :href="'mailto:' + 'events@kommesk.kz'">events@kommesk.kz</a>
                <div class="footer__subname footer__subname-second">{{ $t('footer.text5') }}</div>
                <a class="footer__subdescr footer__subdescr-second" :href="'mailto:' + 'events@kommesk.kz'">events@kommesk.kz</a>
            </div>
            <div class="footer__column">
                <svg width="84" height="19" viewBox="0 0 84 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M76.4561 5.62332L80.8421 2.21524L84 7.41256L78.6491 9.5426L83.9123 11.8431L80.9298 16.87L76.4561 13.3767L77.1579 19H70.8421L71.6316 13.3767L67.2456 16.87L64.0877 11.6726L69.3509 9.5426L64 7.41256L67.1579 2.21524L71.5439 5.62332L70.8421 0H77.1579L76.4561 5.62332Z" fill="#00DBFF"/>
                    <path d="M44.4561 5.62332L48.8421 2.21524L52 7.41256L46.6491 9.5426L51.9123 11.8431L48.9298 16.87L44.4561 13.3767L45.1579 19H38.8421L39.6316 13.3767L35.2456 16.87L32.0877 11.6726L37.3509 9.5426L32 7.41256L35.1579 2.21524L39.5439 5.62332L38.8421 0H45.1579L44.4561 5.62332Z" fill="#00DBFF"/>
                    <path d="M12.4561 5.62332L16.8421 2.21524L20 7.41256L14.6491 9.5426L19.9123 11.8431L16.9298 16.87L12.4561 13.3767L13.1579 19H6.84211L7.63158 13.3767L3.24561 16.87L0.0877182 11.6726L5.35088 9.5426L0 7.41256L3.1579 2.21524L7.54386 5.62332L6.84211 0H13.1579L12.4561 5.62332Z" fill="#00DBFF"/>
                </svg>
            </div>
        </div>
    </footer>
</template>

<script scoped>
    export default {
        data() {
            return {

            }
        }
    }
</script>

<style lang="scss" scoped>
.footer {
    padding: 10px 15px; 
    &__container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        justify-content: space-between;
        background-color: #060A2D;
        padding: 30px;
        gap: 10px;
        @media screen and (max-width: 992px) {
            grid-template-columns: 1fr 1fr;
        }
    }
    &__column {
        display: grid;
        &:nth-child(2) {
            grid-row-start: 2;
            @media screen and (max-width: 992px) {
                grid-row-start: 4;
                grid-column-start: 1;
                grid-column-end: 3;
            }
        }
        &:nth-child(3) {
            grid-row-start: 1;
            grid-row-end: 3;
            @media screen and (max-width: 992px) {
                grid-row-start: 2;
                grid-column-start: 1;
            }
        }
        &:nth-child(4) {
            grid-row-start: 1;
            grid-row-end: 3;
            @media screen and (max-width: 992px) {
                grid-column-start: 2;
                grid-row-start: 2;
            }
        }
        &:nth-child(5) {
            grid-row-start: 1;
            grid-row-end: 3;
            grid-column-start: 4;
            grid-column-end: 6;
            @media screen and (max-width: 992px) {
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row-start: 3;
            }
        }
        &:nth-child(6) {
            grid-row-start: 1;
            grid-row-end: 3;
            justify-self: end;
            @media screen and (max-width: 992px) {
                grid-column-start: 2;
            }
        }
    }
    &__icon {
        display: block;
        margin-bottom: 60px;
    }
    &__descr {
        font-family: 'Geologica', sans-serif;
        font-size: 12px;
        font-weight: 250;
        line-height: 15px;
        color: #fff;
        max-width: 277px;

    }
    &__name {        
        font-size: 16px;
        font-weight: 700;
        line-height: 21px;
        color: #00DBFF;
        margin-bottom: 21px;
        @media screen and (max-width: 395px) {
            font-size: 12px;
        }
    }
    &__list {
    }
    &__item {
        margin-bottom: 18px;
    }
    &__link {
        display: flex;
        align-items: center;
        gap: 18px;
    }
    &__item-logo {
    }
    &__span {
        font-family: 'Geologica', sans-serif;
        font-size: 14px;
        font-weight: 250;
        line-height: 18px;
        color: #fff;
    }
    &__logo {
        display: block;
        margin-bottom: 26.33px;
    }
    &__subname {
        font-family: 'Geologica', sans-serif;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;        
        max-width: 300px;
        letter-spacing: 1px;

        @media screen and (max-width: 395px) {
            font-size: 12px;
        }
    }
    &__subname-first {
        color: #FFF800;
        margin-bottom: 5px;

    }
    &__subname-second {
        color: #12FF4F;
    }
    &__subdescr {
        font-family: 'Geologica', sans-serif;
        font-size: 14px;
        font-weight: 250;
        line-height: 18px;
        color: #fff;
        max-width: 329px;
    }
    &__subdescr-strong {
        font-weight: 400;
    }
    &__subdescr-first {
        margin-bottom: 10px;
    }
    &__subdescr-second {

    }
}
</style>