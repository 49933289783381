<template>
    <div class="menu" :class="{'menu--active' : active_here}">
        <ul class="menu__list">
            <!-- <li class="menu__item">
                <a href="#head1" class="menu__link" @click="anchor($event, 'head1')">
                    {{ $t('registry') }}  
                </a>
            </li> -->
            <li class="menu__item">
                <a href="#head2" class="menu__link" @click="anchor($event, 'head2')">
                    {{ $t('about_forum.title') }}  
                </a>
            </li>
            <li v-if="false" class="menu__item">
                <a href="#head8" class="menu__link" @click="anchor($event, 'head8')">
                    {{ $t('program') }}
                </a>
            </li>
            <li class="menu__item">
                <a href="#head3" class="menu__link" @click="anchor($event, 'head3')">
                    {{ $t('nomination.title') }}
                </a>
            </li>
            <li class="menu__item">
                <a href="#head5" class="menu__link" @click="anchor($event, 'head5')">
                    {{ $t('headers.item1') }}  
                </a>
            </li>
            <li v-if="true" class="menu__item">
                <a href="#head9" class="menu__link" @click="anchor($event, 'head9')">
                    {{ $t('headers.item2') }}  
                </a>
            </li>
            <li class="menu__item">
                <a href="#head7" class="menu__link" @click="anchor($event, 'head7')">
                    {{ $t('contacts') }}  
                </a>
            </li>
        </ul>
        <div class="menu__inner" @click="closeMenu">
            <div class="menu__line"></div>
            <div class="menu__line"></div>
        </div>
    </div>
</template>

<script scoped>
    export default {
        name: 'ForumMenu',
        props: {
            active: {
                type: Boolean,
                require: true,
            }
        },
        data() {
            return {
                active_here: false,
            }
        },
        watch: {
            active(value) {
                this.active_here = value;
            }
        },
        methods: {
            closeMenu() {
                this.$emit('closeMenu', false);
                this.active_here = false;
            },
            anchor(event, blockID) {
                event.preventDefault();

                const targetElement = document.getElementById(blockID);

                if (targetElement) {
                    // Прокрутите к элементу с учетом смещения (в данном случае, -100 пикселей)
                    window.scrollTo({
                        top: targetElement.offsetTop - 100,
                        behavior: 'smooth',
                    });
                }
                this.closeMenu();
            },
        }

    }
</script>

<style lang="scss" scoped>
.menu {
    position: fixed;
    width: 100%;
    height: 265px;
    background-color: #000;
    z-index: 2;
    transform: translateY(-100%);
    transition: 300ms ease-in-out;
    top: 0;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    &--active {
        transform: translateY(0);
    }
    &__list {
        color: #fff;
        text-align: center;
        padding-top: 25px;
    }
    &__item {
        margin-bottom: 24px;
    }
    &__inner {
        position: relative;
        width: 40px;
        transform: translate(10px,-19px);
    }
    &__line {
        position: absolute;
        width: 100%;
        height: 4px;
        border-radius: 2px;
        background-color: #ffffff;
        margin-bottom: 6px;
        &:nth-child(1) {
            transform: rotate(45deg);
        }
        &:nth-child(2) {
            transform: rotate(-45deg);
            top: 1px;
        }
    }
}
</style>