import { createApp } from 'vue'
import App from './App.vue'
import router from "./router"; 
import './assets/sass/reset.scss'
import './assets/sass/global.scss'
import './assets/sass/fonts.scss';
import { createI18n } from 'vue-i18n';
import Maska from 'maska';
import VueCarousel from 'vue-carousel';
import dataFetching from './axios/instance';
import Pusher from 'pusher-js';
// import Pusher from 'pusher-js/types/src/core/pusher';

import { languages } from './locales/settings.js'
import { defaultLocale } from './locales/settings.js'
const messages = Object.assign(languages);

const i18n = createI18n({
    silentFallbackWarn: true,
    silentTranslationWarn: true,
    locale: defaultLocale,
    fallbackLocale: 'kz',
    warnHtmlInMessage: 'off',
    messages,
});

const app = createApp(App);

// Create a global property or provide Pusher wherever needed
app.config.globalProperties.$pusher = new Pusher('9eed0f9c76dc0800dc4d', {
    cluster: 'ap1',
    // Additional options if needed
});
app.use(router).use(VueCarousel).use(i18n).use(Maska);
app.config.globalProperties.$dataFetching = dataFetching;
app.mount('#app');
