<template>
    <div class="forum-select-lang">
        <div class="forum-select-lang__title" :class="{'forum-select-lang__move' : scrollHeight > 5}">
            {{  $i18n.locale === 'kz'  ? 'Қаз' : 'Рус' }}
        </div>
        
        <div class="forum-select-lang__inner" :class="{'forum-select-lang__move-lang' : scrollHeight > 5}">
            <div class="forum-select-lang__item" @click="select_lang('ru')">Рус</div>
            <div class="forum-select-lang__item" @click="select_lang('kz')">Қаз</div>
        </div>
    </div>
</template>

<script scoped>
    export default {
        props: {
            scrollHeight: {
                type: Number,
                required: true,
            }
        },
        methods : {
            select_lang(lang) {
                this.$i18n.locale = lang;
            }
        }
    }
</script>

<style lang="scss" scoped>
.forum-select-lang {
    position: relative;
    padding: 2px;
    font-size: 13px;
    width: 70px;
    height: 36px;
    text-align: center;
    &__title {
        position: absolute;
        width: 100%;
        height: 100%;
        color: #fff;
        padding-top: 11px;
        background-color: #060A2D;
        border-radius: 10px;
        font-size: 13px;
        font-weight: 400;
        left: 0;
        top: 0;
        z-index: 1;        
    }
    &__move {
        background-color: #fff;
        color: #060A2D;
    }
    &__inner {
        position: absolute;
        padding: 1.5px;
        transition: 300ms;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        border: 1px solid #060A2D;
        border-radius: 13px;
        &:hover { 
            transform: translateY(35px);
        }
    }
    &__move-lang {
        border: 1px solid #fff;
        color: #fff;
        font-weight: 400;
    }
    &__item {
        transition: 200ms;
        cursor: pointer;
        font-size: 12px;
        &:hover {
            opacity: 0.5;
        }
    }
    &:hover {
        .forum-select-lang { 
            &__inner {
                transform: translateY(35px);
            }
        }
    }
}
</style>